import { MicroFrontend } from '@praxis/component-microfrontend'
import { Card, FormField, Grid } from '@enterprise-ui/canvas-ui-react'
import { ChangeEvent, useState } from 'react'
import { ReactAppEnv } from 'common-ui'

export const AssortmentsWithProduct = ({ environment, src }: { environment: ReactAppEnv; src: string }) => {
  const [pid, setPid] = useState('PID-99486')

  return (
    <>
      <Grid.Container>
        <Grid.Item>
          <Card className="hc-pa-normal">
            <Grid.Container>
              <Grid.Item>
                <FormField
                  label="PID"
                  value={pid}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => setPid(event.target.value)}
                />
              </Grid.Item>
            </Grid.Container>
          </Card>
        </Grid.Item>
      </Grid.Container>
      <Grid.Container justify="center">
        <Grid.Item xs={10}>
          <MicroFrontend src={src} environment={environment} pid={pid} isNew />
        </Grid.Item>
      </Grid.Container>
    </>
  )
}
