import React, { ReactNode } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { createRoot } from 'react-dom/client'
import { onError, reportWebVitals } from '@praxis/component-logging'
import { EnvProvider } from '@praxis/component-runtime-env'
import { useAuth, LoginHandler, ReactAppEnv, MicroFrontendEnvironmentProvider } from 'common-ui'
import { Heading } from '@enterprise-ui/canvas-ui-react'
import App from './App'

// Standard errors will be automatically logged to STDOUT when thrown.
// The logging sidecar in TAP will process and send logs to the appropriate environment in Kibana.
// Learn more about TAP sidecars: https://tapdocs.prod.target.com/runtime/sidecars/#_platform_sidecars
window.onerror = onError

// If you want to start measuring performance in your app, enable the reportWebVitals()
// function below. Learn more: https://praxis.prod.target.com/components/logging#quick-start-metrics
reportWebVitals()

const RedirectLogin = ({ login }: { login: LoginHandler }) => {
  login({ redirect: window.location.href })
  return null
}

const Login = ({ children }: { children: ReactNode }) => {
  const { login, isAuthenticated } = useAuth()

  if (!isAuthenticated()) {
    return (
      <Routes>
        <Route path="/cypress" element={<Heading>Cypress landing page</Heading>} />
        <Route path="/*" element={<RedirectLogin login={login} />} />
      </Routes>
    )
  }
  return <>{children}</>
}

const root = createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <EnvProvider configPath={process.env.NODE_ENV === 'development' ? '/config.json' : '/app-environment'}>
    {({ env: { environment } }) => (
      <BrowserRouter>
        <MicroFrontendEnvironmentProvider environment={environment as ReactAppEnv}>
          <Login>
            <App environment={environment as ReactAppEnv} />
          </Login>
        </MicroFrontendEnvironmentProvider>
      </BrowserRouter>
    )}
  </EnvProvider>
)
