import { Layout } from '@enterprise-ui/canvas-ui-react'
import { Outlet } from 'react-router-dom'
import { Header } from './Header'

export const Main = () => {
  return (
    <Layout fullWidth theme={'default'} darkMode="false">
      <Header />
      <Layout.Body includeRail>
        <Outlet />
      </Layout.Body>
    </Layout>
  )
}
