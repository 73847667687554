"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserRole = void 0;
// Must exactly match pg role for permissions to function properly
var INTERNAL;
(function (INTERNAL) {
    INTERNAL["ADMIN"] = "TILE_ADMIN";
    INTERNAL["USER"] = "TILE_USER";
})(INTERNAL || (INTERNAL = {}));
var EXTERNAL;
(function (EXTERNAL) {
    EXTERNAL["BASE_USER"] = "TILE_EXTERNAL_BASE_USER";
    EXTERNAL["CATEGORY_MANAGER"] = "TILE_EXTERNAL_CATEGORY_MANAGER";
})(EXTERNAL || (EXTERNAL = {}));
exports.UserRole = {
    INTERNAL: INTERNAL,
    EXTERNAL: EXTERNAL,
};
