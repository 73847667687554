import { MicroFrontend } from '@praxis/component-microfrontend'
import { ReactAppEnv, envConfigs, Main, MicroFrontendEnvironmentProvider, isPol } from 'common-ui'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import initSwitcher from 'favicon-switcher/dist/index.esm'
import { useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'
import { AssortmentsWithProduct } from './spark-microfrontends'
import { POLAccountSwitcher } from './POLAccountSwitcher'

const App = ({ environment }: { environment: ReactAppEnv }) => {
  useEffect(() => {
    initSwitcher()
  }, [])

  return (
    <MicroFrontendEnvironmentProvider environment={environment}>
      <POLAccountSwitcher apiUrl={envConfigs[environment].apiUrl}>
        {/* Web "MFE" handles its own routing, layout, etc... */}
        <MicroFrontend
          environment={environment}
          src={(isPol ? envConfigs[environment].pol : envConfigs[environment]).mfe.web}
        />
        {/* Spark MFE workspaces */}
        <Routes>
          <Route path="/spark-mfes" element={<Main />}>
            <Route
              path="/spark-mfes/assortments-with-product"
              element={
                <AssortmentsWithProduct
                  environment={environment}
                  src={(isPol ? envConfigs[environment].pol : envConfigs[environment]).mfe.sparkAssortmentsWithProduct}
                />
              }
            />
          </Route>
        </Routes>
      </POLAccountSwitcher>
    </MicroFrontendEnvironmentProvider>
  )
}

export default App
