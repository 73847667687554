"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReuploadNotEditableHeaderSuffix = exports.ExcelReuploadHeadings = void 0;
// For Kelly Breuer with Connected Storytelling
var ExcelReuploadHeadings;
(function (ExcelReuploadHeadings) {
    ExcelReuploadHeadings["ABCRank"] = "ABC Rank";
    ExcelReuploadHeadings["SetDate"] = "Store Set Date";
    ExcelReuploadHeadings["DigitalDate"] = "Digital Set Date";
    ExcelReuploadHeadings["MarkdownDate"] = "Markdown Date";
    ExcelReuploadHeadings["NewOrCarryForward"] = "New/CF";
    ExcelReuploadHeadings["StoreCount"] = "Store Count";
    ExcelReuploadHeadings["ExclusiveYesOrNo"] = "Exclusive Yes or No";
})(ExcelReuploadHeadings || (exports.ExcelReuploadHeadings = ExcelReuploadHeadings = {}));
exports.ReuploadNotEditableHeaderSuffix = ' - (not editable)';
