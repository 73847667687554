import { ToasterToastProps, useToaster } from '@enterprise-ui/canvas-ui-react'

export const useToast = () => {
  const makeToast = useToaster()

  const addToast = ({
    autoHideDuration = 5000,
    heading = '',
    caption = '',
    links = [],
    type = 'success',
    message = '',
    ...props
  }: ToasterToastProps): void => {
    makeToast({
      autoHideDuration,
      heading,
      caption,
      links,
      type,
      message,
      ...props,
    })
  }

  return addToast
}
