"use strict";
var _a, _b;
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationSettings = exports.NotificationTypes = void 0;
var NotificationTypes;
(function (NotificationTypes) {
    NotificationTypes["ExcelExportDownload"] = "EXCEL_EXPORT_DOWNLOAD";
    NotificationTypes["FolderExportDownload"] = "FOLDER_EXPORT_DOWNLOAD";
    NotificationTypes["ShareDirectory"] = "SHARE_DIRECTORY";
    NotificationTypes["CommentMention"] = "COMMENT_MENTION";
    NotificationTypes["TileRemovedFromAssortment"] = "TILE_REMOVED_FROM_ASSORTMENT";
    NotificationTypes["TileDroppedIntoSpark"] = "TILE_DROPPED_INTO_SPARK";
})(NotificationTypes || (exports.NotificationTypes = NotificationTypes = {}));
exports.NotificationSettings = {
    Email: (_a = {},
        _a[NotificationTypes.ShareDirectory] = 'EMAIL_SHARE_DIRECTORY',
        _a[NotificationTypes.CommentMention] = 'EMAIL_COMMENT_MENTION',
        _a[NotificationTypes.TileRemovedFromAssortment] = 'EMAIL_TILE_REMOVED_FROM_ASSORTMENT',
        _a[NotificationTypes.TileDroppedIntoSpark] = 'EMAIL_TILE_DROPPED_INTO_SPARK',
        _a),
    Browser: (_b = {},
        _b[NotificationTypes.ExcelExportDownload] = 'BROWSER_EXCEL_EXPORT_DOWNLOAD',
        _b[NotificationTypes.FolderExportDownload] = 'BROWSER_FOLDER_EXPORT_DOWNLOAD',
        _b[NotificationTypes.ShareDirectory] = 'BROWSER_SHARE_DIRECTORY',
        _b[NotificationTypes.CommentMention] = 'BROWSER_COMMENT_MENTION',
        _b[NotificationTypes.TileRemovedFromAssortment] = 'BROWSER_TILE_REMOVED_FROM_ASSORTMENT',
        _b[NotificationTypes.TileDroppedIntoSpark] = 'BROWSER_TILE_DROPPED_INTO_SPARK',
        _b),
};
