import { ProgressBar } from '@enterprise-ui/canvas-ui-react'
import { noop } from 'lodash'
import { createContext, ReactNode, useContext, useMemo, useRef, useState } from 'react'

interface IProgressBarContext {
  incrementProgress: (amountToAdd: number) => void
  startUploading: (numItems: number) => void
  startUploadingIndeterminate: () => void
  finishUploading: (amountToRemove?: number) => void
}

export const ProgressBarContext = createContext<IProgressBarContext>({
  incrementProgress: noop,
  startUploading: noop,
  startUploadingIndeterminate: noop,
  finishUploading: noop,
})

interface IProgressBarValueContext {
  indeterminate: boolean
  progress: number
}

export const ProgressBarValueContext = createContext<IProgressBarValueContext>({
  indeterminate: false,
  progress: 0,
})

interface PropTypes {
  children: ReactNode
}

export const ProgressBarProvider = ({ children }: PropTypes) => {
  const numItemsUploading = useRef(0)
  const [progress, setProgress] = useState<number>(0)
  const [indeterminate, setIndeterminate] = useState(false)

  const startUploading = (numItems: number) => {
    numItemsUploading.current = numItemsUploading.current + numItems
  }

  const startUploadingIndeterminate = () => {
    setIndeterminate(true)
  }

  const finishUploading = (numItems?: number) => {
    numItemsUploading.current = numItems ? numItemsUploading.current - numItems : 0

    if (numItemsUploading.current === 0) {
      setProgress(0)
      setIndeterminate(false)
    }
  }

  let incrementProgress = (amountToAdd: number) => {
    if (numItemsUploading.current > 0) {
      setProgress((prevProgress) => prevProgress + amountToAdd / numItemsUploading.current)
    }
  }

  const memoizedProvider = useMemo(
    () => (
      <ProgressBarContext.Provider
        value={{
          incrementProgress,
          startUploading,
          startUploadingIndeterminate,
          finishUploading,
        }}
      >
        {children}
      </ProgressBarContext.Provider>
    ),
    [children]
  )

  return (
    <ProgressBarValueContext.Provider value={{ progress, indeterminate }}>
      {memoizedProvider}
    </ProgressBarValueContext.Provider>
  )
}

export const useProgressBar = () => useContext(ProgressBarContext)

export const ProgressBarConsumer = () => (
  <ProgressBarValueContext.Consumer>
    {({ progress, indeterminate }) => (
      <>
        {progress > 0 && <ProgressBar percentComplete={progress} />}
        {indeterminate && <ProgressBar indeterminate />}
      </>
    )}
  </ProgressBarValueContext.Consumer>
)
