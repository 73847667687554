import { useAuth } from '@praxis/component-auth'
import { logEvent, LogLevel } from '@praxis/component-logging'
import { cloneDeep, forEach, set } from 'lodash'
import axios, { AxiosError } from 'axios'
import { useMicroFrontendEnvironment, useProgressBar } from '../contexts'
import { useToast } from './useToast'

export const useFetchGraphQL = () => {
  const auth = useAuth()
  const addToast = useToast()
  const { apiUrl } = useMicroFrontendEnvironment()
  const { incrementProgress } = useProgressBar()

  const accessToken = auth.session?.accessToken

  return async (params: any, variables: any, cacheConfig: any, uploadables: any) => {
    console.debug(`fetching query ${params?.name} with ${JSON.stringify(variables)}`)
    let response: Response

    try {
      let uploadData: any = {}
      if (uploadables) {
        const fd = new FormData()
        forEach(uploadables, (value, key) => {
          fd.append(key, value)
        })
        let prevLoaded = 0
        try {
          uploadData = (
            await axios.post(`${apiUrl}/upload-image`, fd, {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
              onUploadProgress: ({ loaded, total }) => {
                incrementProgress(((loaded - prevLoaded) / total) * 100)
                prevLoaded = loaded
              },
            })
          ).data
        } catch (error: any) {
          const { response: { status, data } = {} } = error as AxiosError
          const { message } = data as any
          if (status! >= 500 || error.message === 'Network Error') {
            logEvent({ message: error.message }, { level: LogLevel.Error })
            throw new Error('Oops! Something went wrong. Please try again.')
          }
          if (status! >= 400) {
            logEvent({ message }, { level: LogLevel.Error })
            throw new Error(message)
          }
        }
      }
      const requestVariables = cloneDeep(variables)
      forEach(uploadData, (value, key) => {
        set(requestVariables, key, value)
      })
      response = await fetch(`${apiUrl}/graphql`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          operationName: params.name,
          query: params.text,
          variables: requestVariables,
        }),
      })
    } catch (e: any) {
      logEvent({ message: e.message }, { level: LogLevel.Error })
      throw e
    }

    const data = await response?.json()

    if (response.status === 401) {
      if (data.errors?.[0].resource === 'Go-Proxy') {
        window.location.href = '/'
      } else {
        window.location.href = 'https://logonservices.iam.target.com/login/responses/accessdenied.html'
      }
    }

    if (response.status !== 200) {
      const responseError = new Error(`Request failed with: ${response.status}`)
      logEvent({ message: responseError.message }, { level: LogLevel.Error })
      return {}
    }

    if (data.errors) {
      for (const error of data.errors) {
        addToast({
          message: error.message,
          type: 'error',
        })
        logEvent({ message: error.message, error }, { level: LogLevel.Error })
      }
    }

    return data
  }
}
