"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PermissionOperationKeys = void 0;
var PermissionOperationKeys;
(function (PermissionOperationKeys) {
    PermissionOperationKeys["SEARCH"] = "Search";
    PermissionOperationKeys["SHARE"] = "Share";
    PermissionOperationKeys["TEMPLATES"] = "Templates";
    PermissionOperationKeys["ACTIVITIES"] = "Activities";
    PermissionOperationKeys["CREATE"] = "Create";
    PermissionOperationKeys["EDIT"] = "Edit";
})(PermissionOperationKeys || (exports.PermissionOperationKeys = PermissionOperationKeys = {}));
