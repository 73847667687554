"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VLOOKUPS_ENABLED_ERROR_MESSAGE = exports.MULTIPLE_FILES_ERROR_MESSAGE = exports.INVALID_FILE_TYPE_ERROR_MESSAGE = exports.MALICIOUS_FILE_ERROR_MESSAGE = exports.NO_TILE_IDS_EXCEL_ERROR_MESSAGE = exports.INCORRECT_ASSORTMENT_UPLOAD = exports.MAX_UPLOAD_SIZE_EXCEL_ERROR_MESSAGE = exports.MAX_UPLOAD_SIZE_EXCEL = exports.MAX_UPLOAD_SIZE_IMAGE_ERROR_MESSAGE = exports.MAX_UPLOAD_SIZE_IMAGE = void 0;
/// IMAGE ERRORS ///
// 10MB
exports.MAX_UPLOAD_SIZE_IMAGE = 10485760;
exports.MAX_UPLOAD_SIZE_IMAGE_ERROR_MESSAGE = 'Upload error: File exceeds 10mb, please try a smaller file';
/// EXCEL ERRORS ///
// 50MB - max file size for sync virus scan
exports.MAX_UPLOAD_SIZE_EXCEL = 52428800;
exports.MAX_UPLOAD_SIZE_EXCEL_ERROR_MESSAGE = 'Upload error: File exceeds 50mb, please try a smaller file';
exports.INCORRECT_ASSORTMENT_UPLOAD = 'Upload error: Incorrect assortment, please make sure you choose the correct assortment to upload to.';
exports.NO_TILE_IDS_EXCEL_ERROR_MESSAGE = 'Upload error: When exporting please use the option "Is template for re-upload?"';
/// GENERAL ERRORS ///
exports.MALICIOUS_FILE_ERROR_MESSAGE = 'Upload error: Potentially malicious file detected';
exports.INVALID_FILE_TYPE_ERROR_MESSAGE = 'Upload error: Invalid file type';
exports.MULTIPLE_FILES_ERROR_MESSAGE = 'Upload error: Only one file allowed.';
exports.VLOOKUPS_ENABLED_ERROR_MESSAGE = 'Please disable vlookups by pasting special as values instead of direct paste';
