import {
  Environment,
  GraphQLResponse,
  Network,
  Observable,
  RecordSource,
  Store,
  SubscribeFunction,
} from 'relay-runtime'
import { createClient, Sink } from 'graphql-ws'
import { useMicroFrontendEnvironment } from '../contexts'
import { useFetchGraphQL } from './useFetchGraphQL'
import RelayModernEnvironment from 'relay-runtime/lib/store/RelayModernEnvironment'

export default function useAppRelayEnvironment() {
  const { websocketUrl } = useMicroFrontendEnvironment()

  const wsClient = createClient({
    url: `${websocketUrl}/graphql`,
    shouldRetry: () => true,
  })

  const subscribe: SubscribeFunction = (operation, variables) => {
    return Observable.create<GraphQLResponse>((sink: Sink) => {
      if (!operation.text) {
        return sink.error(new Error('Operation text cannot be empty'))
      }
      return wsClient.subscribe(
        {
          operationName: operation.name,
          query: operation.text,
          variables,
        },
        sink
      )
    })
  }

  const fetchGraphQL = useFetchGraphQL()

  return new Environment({
    network: Network.create(fetchGraphQL, subscribe),
    store: new Store(new RecordSource(), { gcReleaseBufferSize: 42 }),
    // missingFieldHandlers,
  })
}
