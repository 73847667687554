import { RelayEnvironmentProvider } from 'react-relay/hooks'
import { ReactNode } from 'react'
import useAppRelayEnvironment from './hooks/useAppRelayEnvironment'

interface PropTypes {
  children: ReactNode
}

export const AppRelayEnvironmentProvider = ({ children }: PropTypes) => {
  const relayEnvironment = useAppRelayEnvironment()

  return <RelayEnvironmentProvider environment={relayEnvironment}>{children}</RelayEnvironmentProvider>
}
