/*

👋 “Hey” from the Praxis team!

'apiConfig' is one pattern that can be used to set environment-based values at build time.
This happens by creating an environment variable REACT_APP_ENV, and then matching corresponding
keys to each environment in the 'envConfigs' object below. To learn more about how to set
REACT_APP_ENV, view the README generated with this app.


For an alternative pattern - using TAP configuration to set environment-based values at cluster initialization - see:
@praxis/component-runtime-env - https://praxis.prod.target.com/components/runtime-env

*/

import type { AuthConfig } from '@praxis/component-auth'
import { config as analyticsConfig } from '@praxis/component-analytics'

export interface MfeConfig {
  web: string
  sparkAssortmentsWithProduct: string
}

export interface ApiConfig extends CommonConfig {
  apiUrl: string
  tileUrl: string
  websocketUrl: string
  readonly mfe: MfeConfig
}

interface AnalyticsConfig {
  appName: string
  apiKey: string
  url: string
}

interface CommonConfig {
  readonly auth: AuthConfig
  readonly pol: { auth: AuthConfig; analytics: AnalyticsConfig; mfe: MfeConfig }
  readonly analytics: AnalyticsConfig
}

export type ReactAppEnv = 'local' | 'velaapp1' | 'velaapp2' | 'stax' | 'dev' | 'stage' | 'prod'
type EnvConfigs = {
  readonly [key in ReactAppEnv]: ApiConfig
}

const commonConfig: CommonConfig = {
  auth: {
    clientId: 'tile_npe_im',
    authorizationUrl: 'https://oauth.iam.perf.target.com/auth/oauth/v2/authorize',
    logoutUrl: 'https://logonservices.iam.perf.target.com/login/responses/logoff.html',
  },
  pol: {
    auth: {
      authorizationUrl: 'https://oauth.iam.perf.partnersonline.com/auth/oauth/v2/tgt/authorize/nla/1',
      logoutUrl: 'https://oauth.iam.perf.partnersonline.com/login/responses/logoff.html',
      clientId: 'tile_npe_im',
    },
    analytics: {
      appName: 'tile',
      apiKey: 'd199c148bdecf0992c8067818078c2484479af30',
      url: analyticsConfig.api.external.stage,
    },
    mfe: {
      web: 'web@//tile.perf.partnersonline.com/webRemoteEntry.js',
      sparkAssortmentsWithProduct:
        'sparkAssortmentsWithProduct@//tile.perf.partnersonline.com/sparkAssortmentsWithProductRemoteEntry.js',
    },
  },
  analytics: {
    appName: 'tile',
    apiKey: 'd199c148bdecf0992c8067818078c2484479af30',
    url: analyticsConfig.api.internal.stage,
  },
}

export const envConfigs: EnvConfigs = {
  local: {
    ...commonConfig,
    tileUrl: 'http://tile.localhost:3000',
    apiUrl: 'http://go-proxy.tile.localhost:9999',
    websocketUrl: 'ws://go-proxy.tile.localhost:9999',
    // Config for running nginx in docker
    // tileUrl: 'http://tile',
    // apiUrl: '/tile-api',
    // websocketUrl: 'ws://tile/tile-api',
    mfe: {
      web: 'web@//web.tile.localhost:3001/remoteEntry.js',
      sparkAssortmentsWithProduct: 'sparkAssortmentsWithProduct@//web.tile.localhost:3003/remoteEntry.js',
      // Config for running nginx in docker
      // web: 'web@//tile.localhost/webRemoteEntry.js', // Config for running nginx in docker
      // sparkAssortmentsWithProduct:
      //   'sparkAssortmentsWithProduct@//tile.localhost/sparkAssortmentsWithProductRemoteEntry.js',
    },
    analytics: {
      ...commonConfig.analytics,
      url: 'http://api.tile.localhost:9090/analytics',
    },
    pol: {
      ...commonConfig.pol,
      analytics: {
        ...commonConfig.pol.analytics,
        url: 'http://api.tile.localhost:9090/analytics',
      },
      mfe: {
        web: 'web@//web.tile.localhost:3001/remoteEntry.js',
        sparkAssortmentsWithProduct: 'sparkAssortmentsWithProduct@//web.tile.localhost:3003/remoteEntry.js',
      },
    },
  },
  velaapp1: {
    ...commonConfig,
    tileUrl: 'http://tile1',
    apiUrl: '/tile-api',
    websocketUrl: 'ws://tile1/tile-api',
    mfe: {
      web: 'web@//tile1/webRemoteEntry.js',
      sparkAssortmentsWithProduct: 'sparkAssortmentsWithProduct@//tile1/sparkAssortmentsWithProductRemoteEntry.js',
    },
    analytics: {
      ...commonConfig.analytics,
      url: 'http://api.tile1:9090/analytics',
    },
  },
  velaapp2: {
    ...commonConfig,
    tileUrl: 'http://tile2',
    apiUrl: '/tile-api',
    websocketUrl: 'ws://tile2/tile-api',
    mfe: {
      web: 'web@//tile2/webRemoteEntry.js',
      sparkAssortmentsWithProduct: 'sparkAssortmentsWithProduct@//tile2/sparkAssortmentsWithProductRemoteEntry.js',
    },
    analytics: {
      ...commonConfig.analytics,
      url: 'http://api.tile2:9090/analytics',
    },
  },
  stax: {
    ...commonConfig,
    tileUrl: 'https://shortstacksproxy.prod.target.com/',
    apiUrl: '/tile-api',
    websocketUrl: 'wss://tile.dev.target.com/tile-api',
    mfe: {
      web: 'web@//shortstacksproxy.prod.target.com/webRemoteEntry.js',
      sparkAssortmentsWithProduct:
        'sparkAssortmentsWithProduct@//shortstacksproxy.prod.target.com/sparkAssortmentsWithProductRemoteEntry.js',
    },
  },
  dev: {
    ...commonConfig,
    tileUrl: 'https://tile.dev.target.com',
    apiUrl: '/tile-api',
    websocketUrl: 'wss://tile.dev.target.com/tile-api',
    mfe: {
      web: 'web@//tile.dev.target.com/webRemoteEntry.js',
      sparkAssortmentsWithProduct:
        'sparkAssortmentsWithProduct@//tile.dev.target.com/sparkAssortmentsWithProductRemoteEntry.js',
    },
  },
  stage: {
    ...commonConfig,
    tileUrl: 'https://tile-stage.dev.target.com',
    apiUrl: '/tile-api',
    websocketUrl: 'wss://tile-stage.dev.target.com/tile-api',
    mfe: {
      web: 'web@//tile-stage.dev.target.com/webRemoteEntry.js',
      sparkAssortmentsWithProduct:
        'sparkAssortmentsWithProduct@//tile-stage.dev.target.com/sparkAssortmentsWithProductRemoteEntry.js',
    },
  },
  prod: {
    ...commonConfig,
    auth: {
      authorizationUrl: 'https://oauth.iam.target.com/auth/oauth/v2/authorize',
      logoutUrl: 'https://logonservices.iam.target.com/login/responses/logoff.html',
      clientId: 'tile_prod_im',
    },
    pol: {
      auth: {
        authorizationUrl: 'https://oauth.iam.partnersonline.com/auth/oauth/v2/tgt/authorize/nla/1',
        logoutUrl: 'https://oauth.iam.partnersonline.com/login/responses/logoff.html',
        clientId: 'tile_prod_im',
      },
      analytics: {
        ...commonConfig.pol.analytics,
        apiKey: '519d852a9008283228f336576b7afb26ae2f7b2f',
        url: analyticsConfig.api.external.prod,
      },
      mfe: {
        web: 'web@//tile.partnersonline.com/webRemoteEntry.js',
        sparkAssortmentsWithProduct:
          'sparkAssortmentsWithProduct@//tile.partnersonline.com/sparkAssortmentsWithProductRemoteEntry.js',
      },
    },
    tileUrl: 'https://tile.prod.target.com',
    apiUrl: '/tile-api',
    websocketUrl: 'wss://tile.prod.target.com/tile-api',
    mfe: {
      web: 'web@//tile.prod.target.com/webRemoteEntry.js',
      sparkAssortmentsWithProduct:
        'sparkAssortmentsWithProduct@//tile.prod.target.com/sparkAssortmentsWithProductRemoteEntry.js',
    },
    analytics: {
      ...commonConfig.analytics,
      apiKey: '519d852a9008283228f336576b7afb26ae2f7b2f',
      url: analyticsConfig.api.internal.prod,
    },
  },
}
