import { isPol, useAuth } from 'common-ui'
import React, { useCallback, useEffect, useState } from 'react'
import Cookies from 'js-cookie'

interface POLAccountSwitcherProps {
  children: React.ReactNode
  apiUrl: string
}

export const POLAccountSwitcher = ({ children, apiUrl }: POLAccountSwitcherProps) => {
  const { isAuthenticated, session } = useAuth()
  const accessToken = session?.accessToken

  const [isLoggedIn, setIsLoggedIn] = useState<string>()
  const [error, setError] = useState<string>()

  const clearLocalStorage = () => {
    window.localStorage.removeItem('access_token')
    window.localStorage.removeItem('id_token')
    Cookies.remove('SSOSESSIONINFO')
    Cookies.remove('id2Token')
    setIsLoggedIn(undefined)
    setError(undefined)
  }

  const loggedInUserLanId = session?.userInfo?.lanId
  const checkCookie = useCallback(() => {
    const sessionJson = getCookieSessionJson(Cookies.get('SSOSESSIONINFO'))

    // console.debug('Logged in User: ' + loggedInUser)
    // console.debug('Session user: ' + sessionJson?.user)
    if (sessionJson && !sessionJson?.login_status) {
      // the session is logged out! Clear everything and relog!
      clearLocalStorage()
      window.location.reload()
    }
    if (loggedInUserLanId !== undefined && sessionJson?.user !== undefined) {
      // verify the user is the same one from cookie set by assent!
      if (loggedInUserLanId !== String(sessionJson?.user)) {
        clearLocalStorage()
        window.location.reload()
      }
    }
  }, [loggedInUserLanId])

  const login = useCallback(() => {
    if (isAuthenticated() && accessToken) {
      fetch(`${apiUrl}/login`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        credentials: 'include',
      })
        .then((response) => response.json())
        .then((data) => {
          setIsLoggedIn(data.loggedIn)
          if (data.errors?.[1]?.reason?.includes('Token used before issued')) {
            setTimeout(login, 1000)
          } else {
            setError(data.error)
          }
        })
    }
  }, [accessToken, apiUrl, isAuthenticated])

  useEffect(() => {
    if (accessToken) {
      login()
    }
  }, [accessToken, login])

  useEffect(() => {
    if (isPol) {
      // check for session change (default every 2 seconds)
      const intervalId = setInterval(checkCookie, 2000)
      return () => {
        clearInterval(intervalId)
      }
    }
  }, [checkCookie])

  if (error) {
    return <>{error}</>
  }

  if (!isLoggedIn) {
    return null
  }

  return <>{children}</>
}

const getCookieSessionJson = (cookie: any): any => {
  if (cookie) {
    const SSOSESSIONINFOcoded = window.atob(cookie)
    return JSON.parse(SSOSESSIONINFOcoded)
  }
}
