import { Grid, Layout, Image } from '@enterprise-ui/canvas-ui-react'
import { Link } from 'react-router-dom'
import { polTileLogo, tileLogo } from 'constants-library'
import { isPol } from '../../utils'
// TODO: Get ThemeProvider moved into common-ui
// import { useContext } from 'react'
// import { ThemeContext } from '../../contexts/Theme'
// import { useMyUser } from '../../hooks'
// TODO: use webpack to compile rather than tsc so that we can get access to scss files
// import './Header.scss'

// const ThemeToggle = () => {
//   // const { handleToggleTheme, currentTheme } = useContext(ThemeContext)
//   return (
//     <FormField
//       label="Dark mode"
//       type="toggle"
//       onChange={handleToggleTheme}
//       checked={currentTheme === 'DARK'}
//       className="dark-mode-toggle"
//     />
//   )
// }

export const Header = () => {
  const tileLogoUrl = isPol ? polTileLogo : tileLogo
  return (
    <Layout.Header includeRail className="header">
      <Grid.Container direction="row" align="center" className="hc-pl-lg">
        <Grid.Item>
          <Link to="/">
            <Image alt="Tile header logo" src={tileLogoUrl} height={'40px'} width={'100px'} type="contain" />
          </Link>
        </Grid.Item>
        {/*<Grid.Item className="hc-ml-auto">*/}
        {/*  <ThemeToggle />*/}
        {/*</Grid.Item>*/}
      </Grid.Container>
    </Layout.Header>
  )
}
